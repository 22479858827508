<template>
    <v-container fluid>

        <v-data-table :items="devices" :loading="loading" :headers="tableHeaders"
            :footer-props="{ itemsPerPageOptions: [25, 50] }" :search="search" :custom-filter="filterTable"
            :custom-sort="customSort" class="dataTable" dense>
            <template v-slot:top>
                <!-- <v-text-field v-model="search" label="Keresés" class="mx-4"></v-text-field> -->

                <span class="mx-4">Utolsó kigyűjtés: {{ collected_on }}</span>
            </template>
            <template v-slot:item.name="{ item }">
                <template v-if="item.ucrm_id">
                    <a target="_blank" :href="'https://ucrm.keviwlan.hu/client/' + item.ucrm_id">{{
                            item.properties["hostname"]
                    }}</a>
                </template>
                <template v-else>
                    {{ item.properties["hostname"] }}
                </template>
            </template>

            <template v-slot:item.currentSignal="{ item }">
                {{ item.properties['signal@ath0~current'] }} dBm
            </template>

            <template v-slot:item.mac="{ item }">
                {{ item.properties['mac'] }}
            </template>
            <template v-slot:item.service_city="{ item }">
                <template v-if="item.ucrm_details">
                    {{ item.ucrm_details.city }}
                </template>
                <template v-else>
                    N/A
                </template>
            </template>
            <template v-slot:item.winter="{ item }">
                <ul>
                    <li v-for="metric, index in item.metrics" :key="index">
                        [{{ metric.from_date.split(" ")[0] }}]:
                        <template v-if="metric.best_signal && metric.worst_signal">
                            <span style="color: green; font-weight: bold;">
                                {{ metric.best_signal.signal || "N/A" }} dBm
                            </span> <span style="color: teal; font-weight: bold;">
                                {{ metric.average || "N/A" }} dBm
                            </span>
                            <span style="color: red; font-weight: bold;">
                                {{ metric.worst_signal.signal || "N/A " }} dBm
                            </span>
                        </template>
                        <template v-else>
                            N/A
                        </template>
                    </li>
                </ul>
            </template>

            <template v-slot:item.service_address="{ item }">
                <template v-if="item.ucrm_details.street1">
                    <a target="blank"
                        :href="'https://ucrm.keviwlan.hu/client/service/' + item.ucrm_details.service_id">{{
                                item.ucrm_details.street1
                        }}</a>
                </template>
                <template v-else>
                    N/A
                </template>
            </template>


        </v-data-table>
    </v-container>
</template>

<script>
import { getDevices } from "../api/ac2";
export default {
    name: "DeviceStat",
    data: () => ({
        loading: true,
        deviceStat: {},
        devices: [],
        collected_on: null,
        search: "",
        tableHeaders: [
            {
                text: 'Név',
                sortable: true,
                value: 'name',
            },
            {
                text: 'Jelszint kigyűjtéskor',
                sortable: false,
                value: 'currentSignal',
            },
            {
                text: 'Télen jelszintek',
                sortable: false,
                value: 'winter',
            },
            {
                text: 'Település',
                value: 'service_city',
            },
            {
                text: 'Cím',
                sortable: false,
                value: 'service_address'
            },
        ]
    }),
    mounted: function () {
        this.$store.commit("app/setTitle", "Leromlott jelszintek");
        this.loadDevices().finally(() => this.loading = false);
    },
    methods: {
        loadDevices: async function () {
            await getDevices().then((resp) => {
                this.devices = resp.data.data;
                this.devices = this.devices.filter((el) => !el.summary.deviceNodeName.startsWith("10."));
                this.collected_on = resp.data.collected_on;
            });
        },
        filterTable(value, search, item) {
            return value != null &&
                search != null &&
                typeof value === 'string' &&
                item['name'].toString().indexOf(search) !== -1;
        },
        customSort: function (items, index, isDesc) {
            items.sort((a, b) => {
                if (index[0] == 'name') {
                    if (!isDesc[0]) {
                        return a["properties"]["deviceTag"].toLowerCase().localeCompare(b["properties"]["deviceTag"].toLowerCase());
                    } else {
                        return b["properties"]["deviceTag"].toLowerCase().localeCompare(a["properties"]["deviceTag"].toLowerCase());
                    }
                }
                else if (index[0] == "service_city") {
                    if (!isDesc[0]) {
                        return a["ucrm_details"]["city"].toLowerCase().localeCompare(b["ucrm_details"]["city"].toLowerCase());
                    } else {
                        return b["ucrm_details"]["city"].toLowerCase().localeCompare(a["ucrm_details"]["city"].toLowerCase());
                    }
                }
            });
            return items;
        }
    }
};
</script>

<style scoped>
.dataTable tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.1);
}
</style>