var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"dataTable",attrs:{"items":_vm.devices,"loading":_vm.loading,"headers":_vm.tableHeaders,"footer-props":{ itemsPerPageOptions: [25, 50] },"search":_vm.search,"custom-filter":_vm.filterTable,"custom-sort":_vm.customSort,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('span',{staticClass:"mx-4"},[_vm._v("Utolsó kigyűjtés: "+_vm._s(_vm.collected_on))])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.ucrm_id)?[_c('a',{attrs:{"target":"_blank","href":'https://ucrm.keviwlan.hu/client/' + item.ucrm_id}},[_vm._v(_vm._s(item.properties["hostname"]))])]:[_vm._v(" "+_vm._s(item.properties["hostname"])+" ")]]}},{key:"item.currentSignal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.properties['signal@ath0~current'])+" dBm ")]}},{key:"item.mac",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.properties['mac'])+" ")]}},{key:"item.service_city",fn:function(ref){
var item = ref.item;
return [(item.ucrm_details)?[_vm._v(" "+_vm._s(item.ucrm_details.city)+" ")]:[_vm._v(" N/A ")]]}},{key:"item.winter",fn:function(ref){
var item = ref.item;
return [_c('ul',_vm._l((item.metrics),function(metric,index){return _c('li',{key:index},[_vm._v(" ["+_vm._s(metric.from_date.split(" ")[0])+"]: "),(metric.best_signal && metric.worst_signal)?[_c('span',{staticStyle:{"color":"green","font-weight":"bold"}},[_vm._v(" "+_vm._s(metric.best_signal.signal || "N/A")+" dBm ")]),_vm._v(" "),_c('span',{staticStyle:{"color":"teal","font-weight":"bold"}},[_vm._v(" "+_vm._s(metric.average || "N/A")+" dBm ")]),_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" "+_vm._s(metric.worst_signal.signal || "N/A ")+" dBm ")])]:[_vm._v(" N/A ")]],2)}),0)]}},{key:"item.service_address",fn:function(ref){
var item = ref.item;
return [(item.ucrm_details.street1)?[_c('a',{attrs:{"target":"blank","href":'https://ucrm.keviwlan.hu/client/service/' + item.ucrm_details.service_id}},[_vm._v(_vm._s(item.ucrm_details.street1))])]:[_vm._v(" N/A ")]]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }