import { API } from ".";

export const getDeviceMetrics = (deviceId, from_time, to_time) => {
  return API.instance.get(`/ac2/devices/${deviceId}/metrics`, {
    params: {
      from_time,
      to_time,
    },
  });
};

export const getDevices = () => {
  return API.instance.get("/ac2/devices");
};
